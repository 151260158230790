import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

// import { Badge } from 'reactstrap';
import img1 from "../images/wine-recommender-01.png";
import img2 from "../images/wine-recommender-02.png";
import img3 from "../images/wine-recommender-03.png";

const PortfolioPage = () => (
  <Layout>
    <SEO
      title="Javier Suzuki | Design & Front-end Development Portfolio - Design System"
      keywords={[`javier`, `suzuki`, `web`, `ui`, `ux`, `design`, `front-end`, `development`, `application`, `ai`, `artificial-intelligence`, `ml`, `machine-learning`, `react`]}
    />
    <div className="portfolio-header">
      <h1>AI-Driven Wine Recommender</h1>
      <h5>Figma + Framer Prototype</h5>
      <p>Prototype for an AI-driven Wine Recommender to be presented at a Wine Expo to promote AI capabilities.</p>
      <p>Made with Figma + Framer and then built with HTML/CSS.</p>
    </div>
    {/* <div className="portfolio-footer">
      <a className="btn btn-primary" href="https://wushka.com.au" target="_blank" rel="noopener noreferrer">View Project</a>
    </div> */}
    <div className="portfolio-content">
      <figure className="portfolio-sample">
        <figcaption className="figheading">Initial Screen</figcaption>
        <img src={img1} alt=""/>
      </figure>
      <figure className="portfolio-sample">
        <figcaption className="figheading">Wine Selection Screen</figcaption>
        <img src={img2} alt=""/>
      </figure>
      <figure className="portfolio-sample">
        <figcaption className="figheading">AI Recommendations Screen</figcaption>
        <img src={img3} alt=""/>
      </figure>
    </div>

  </Layout>
)

export default PortfolioPage
